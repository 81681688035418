.sponsor-link {
    padding: 15px;
    margin: 15px;
    text-align: center;
    box-shadow: 0 0 4px rgba(128, 128, 128, 0.2);
}

@media only screen and (max-width: 500px) {
    .sponsor-link {
        padding: 0;
        margin: 15px 0;
    }
}

.sponsor-level-container {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex !important;
    justify-content: space-around;
    flex-wrap: wrap;
}
